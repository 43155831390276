import React, { ReactElement, useEffect } from "react";
import Parcel from "single-spa-react/parcel";
import { TemplateContextProps } from "@castia/sdk";
import usePlugins from "../../../hooks/usePlugins";
import { transitions } from "../../Transition/transitions";
import styles from "./PluginTemplateComponent.scss";
import { MainTransition } from "../../Transition/MainTransition";
import { Helmet } from "react-helmet";

interface PluginTemplateComponentProps extends TemplateContextProps {
    template: string;
    layoutId?: string;
    transitionType?: string;
    sceneId: string;
}

/**
 * Load a template from a plugin.
 * @param props
 * @constructor
 */
function PluginTemplateComponent(
    props: PluginTemplateComponentProps,
): ReactElement {
    const { findTemplate } = usePlugins();
    const templateToLoad = findTemplate(props.template);
    const layoutToShow =
        templateToLoad.layouts.length > 1 && props.layoutId
            ? templateToLoad.layouts.find(
                  (layout) => layout.id === props.layoutId,
              )
            : templateToLoad.layouts[0];
    const [currentTransitionType, setCurrentTransitionType] =
        React.useState<string>(props.transitionType);

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (props.transitionType) {
            timeout = setTimeout(() => {
                setCurrentTransitionType(props.transitionType);
            }, 2000);
        }

        return () => {
            timeout && clearTimeout(timeout);
        };
    }, [props.transitionType]);

    if (!templateToLoad) {
        return <div>Could not find the requested template</div>;
    }

    const runtimeScale = Math.min(window.screen.width, window.screen.height) / 1080;

    if (props.transitionType) {
        const selectedTransition = transitions[currentTransitionType];

        return (
            <MainTransition
                templateKey={props.sceneId}
                config={selectedTransition}
            >
                <Helmet>
                    <style>
                        {`
                          html {
                            font-size: ${runtimeScale * 16}px !important;
                          }
                        `}
                    </style>
                </Helmet>
                <div
                    className={styles.transitionableContainer}
                    style={{ fontSize: `${runtimeScale * 16}px` }}
                >
                    <Parcel
                        key={templateToLoad.key + layoutToShow.id}
                        config={layoutToShow.component}
                        wrapWith="div"
                        wrapStyle={{ height: "100%" }}
                        {...props}
                    />
                </div>
            </MainTransition>
        );
    }
    return (
        <div
            className={styles.transitionableContainer}
            style={{ fontSize: `${runtimeScale * 16}px` }}
        >
            <Helmet>
                <style>
                    {`
                      html {
                        font-size: ${runtimeScale * 16}px !important;
                      }
                    `}
                </style>
            </Helmet>
            <Parcel
                key={templateToLoad.key + layoutToShow.id}
                config={layoutToShow.component}
                wrapWith="div"
                wrapStyle={{ height: "100%" }}
                {...props}
            />
        </div>
    );
}

export default PluginTemplateComponent;
